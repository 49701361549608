<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12">
        <Information />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <SearchBox />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <SearchResult />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Information from "../components/Search/Information.vue";
import SearchBox from "../components/Search/SearchBox.vue";
import SearchResult from "../components/Search/SearchResult.vue";

export default {
  name: "Search",
  components: {
    Information,
    SearchBox,
    SearchResult
  }
};
</script>
