<template>
  <v-container>
    <v-row>
      <v-col cols="12" xm="12" sm="6" md="4" v-for="(c, i) in results" :key="i">
        <Classroom :numberOf="1" :classrooms="[c]" view="register" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <Batch />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Classroom from "../Classroom";
import Batch from "./Batch";
export default {
  name: "SearchResults",

  components: {
    Classroom,
    Batch
  },
  computed: {
    results() {
      const start =
        (this.$store.state.pagination - 1) * this.$store.state.maxShowPage;
      const end = start + this.$store.state.maxShowPage;
      return this.$store.state.searchResults.slice(start, end);
    }
  },
  methods: {}
};
</script>
