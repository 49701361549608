<template>
  <v-container>
    <p>
      該当の授業コード（時間割にある207から始まる7桁のコード）を入力すると、科目が表示されGoogle
      Classroomに登録できます。詳しくは上の詳細説明を読んでください。
    </p>
    <v-row>
      <v-col cols="12" md="10">
        <v-text-field
          v-model="term"
          label="検索する単語を入力"
          placeholder="授業コードや授業名を入力"
          outlined
          clearable
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-btn
          small
          class="ma-2"
          color="info"
          @click="doSearch()"
          :disabled="showSearchBotton"
          >Classroomを検索</v-btn
        >
      </v-col>
    </v-row>
    <v-row v-if="showResultBox">
      <v-col cols="12" md="12">
        <v-card class="mx-auto" outlined rounded :loading="showLoading">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-subtitle>
                検索結果
              </v-list-item-subtitle>
              <v-card-text v-if="showResultText">
                検索単語: {{ term }} に、 {{ resultNum }}件の結果がありました。
                <span v-if="resultNum > 0">
                  表示: {{ start }} - {{ end }} を表示中
                </span>
              </v-card-text>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "SearchBox",
  data: () => ({
    term: null,
    showResultBox: false,
    showResultText: false,
    showLoading: false
  }),
  computed: {
    resultNum() {
      return this.$store.state.searchResults.length;
    },
    showSearchBotton() {
      return !this.term || this.showResultText;
    },
    start() {
      return (
        (this.$store.state.pagination - 1) * this.$store.state.maxShowPage + 1
      );
    },
    end() {
      const maxShowNum = this.start + this.$store.state.maxShowPage - 1;
      if (maxShowNum <= this.resultNum) {
        return maxShowNum;
      } else {
        return this.resultNum;
      }
    }
  },
  watch: {
    term() {
      this.showResultBox = false;
      this.showResultText = false;
      if (this.term.length > 3) {
        this.doSearch();
      }
    }
  },
  methods: {
    async doSearch() {
      this.track();
      const _sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
      this.$store.commit("SET_PAGINATION", 1);
      this.showResultBox = true;
      this.showLoading = true;
      this.showResultText = false;
      await _sleep(1000);
      this.$store.dispatch("doSearch", this.term).then(() => {
        this.showResultText = true;

        this.showLoading = false;
      });
    },
    track() {
      // TODO: GAタグ
      //   this.$gtag.event("acction", {
      //     event_category: "search",
      //     event_label: "search",
      //     value: this.term
      //   });
    }
  }
};
</script>
