<template>
  <v-container>
    <v-row v-if="length > 1">
      <v-col cols="12" md="12">
        <v-pagination
          @input="setPage"
          :value="page"
          :length="length"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "Batch",

  data() {
    return {};
  },
  computed: {
    length() {
      const resultNum =
        parseInt(
          this.$store.state.searchResults.length / this.$store.state.maxShowPage
        ) + 1;
      return resultNum;
    },
    page() {
      return this.$store.state.pagination;
    }
  },
  methods: {
    setPage(p) {
      this.$store.commit("SET_PAGINATION", p);
    }
  }
};
</script>
